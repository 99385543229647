import dayjs from 'dayjs'

export const columns = [
	{
		prop: 'templateName',
		label: '模板名称',
	},
	{
		prop: 'sendTo',
		label: '接收号码',
	},
	{
		prop: 'sendToName',
		label: '接收人姓名',
	},
	{
		prop: 'content',
		label: '短信内容',
		width: 500,
	},
	{
		prop: 'status',
		label: '发送状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 0 ? '失败' : '成功'
		},
	},
	{
		prop: 'createTime',
		label: '发送时间',
		formatter: function (row, column, cellValue, index) {
			return dayjs(cellValue).format('YYYY-MM-DD HH:mm:ss')
		},
	},
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '搜索',
			attr: 'keyword',
			type: 'search',
			placeholder: '请输入接收人手机号/姓名',
			clearable: true,
		},
		{
			attr: 'createTime',
			label: '发送时间',
			type: 'dateTime',
			placeholder: '选择发送时间',
		},
		{
			attr: 'status',
			label: '发送状态',
			type: 'select',
			placeholder: '请选择发送状态',
			options: [
				{ name: '成功', code: 1 },
				{ name: '失败', code: 0 },
			],
		},
	],
}

export const rules = {
	addAndEdit: {
		name: [{ required: true, message: '请输入角色名称', trigger: 'blur' }],
		dataScope: [{ required: true, message: '请选择数据范围', trigger: 'change' }],
	},
}
